import React from "react";

export default function UserAccount(props) {
  return (
    <div className="mypopup">
      <div className="container">
        <i
          onClick={() => {
            props.setToggleAccount(false);
          }}
          className="fa fa-times"
        ></i>
        <h3>Account Details</h3>
        <div>
          <p>Name : {props.currentUser.Name}</p>
          <p>Phone : {props.currentUser.Phone}</p>
          <p>Email : {props.currentUser.Email}</p>
          <p>Department : {props.currentUser.Department}</p>
          <p>Position : {props.currentUser.Position}</p>
          <p>Role: {props.currentUser.Role}</p>
          {props.currentUser.Status && <p>Status : Active</p>}
        </div>
      </div>
    </div>
  );
}
