import Header2 from "../components/Utils/Header2";
import Navigation from "../components/Utils/Navigation";
import Selector from "../components/Utils/Selector";
import StatsPageBox from "../components/Utils/StatsPageBox";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { useEffect, useState } from "react";
import StackedAreaChart from "../components/Stats/StackedAreaChart";
import BarGraphExpense from "../components/Stats/BarGraphExpense";
import CustomShapeBar from "../components/Stats/CustomShapeBar";

export default function StatisticsPage(props) {
  const [totalVisits, setTotalVisits] = useState();
  const [total, setTotal] = useState([]);

  const [totalDownloads, setTotalDownloads] = useState();
  const [totalSpecificDownloads, setTotalSpecificDownloads] = useState();
  const [totalVisisforToday, setTotalVisitsforToday] = useState();
  const [pageVisitsToday, setPageVisitsToday] = useState();
  const [time, setTime] = useState();
  const [stats, setStats] = useState();
  const [users, setUsers] = useState(0);
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    fetch("/api/data/stats/categorized", {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {


        setStats(data);
      })
      .catch((e) => {
       
      });
  }, []);

  useEffect(() => {
    fetch(`/api/stats/total`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        setTotalVisits(data.total);
        var today = new Date();
        var date =
          today.getDate() +
          " " +
            +
          " " +
          today.getFullYear();

        const mins =
          today.getMinutes() < 10
            ? "0" + today.getMinutes().toString()
            : today.getMinutes();
        var time = today.getHours() + ":" + mins;
        var dateTime = date + " " + time;
        setTime(dateTime);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/downloads/total`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        setTotalDownloads(data.total);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/stats/pages`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        let d = [];
        data.result?.forEach((element) => {
          d.push({ name: element.Page, value: parseInt(element.total) });
        });

        let x = d.sort(function (a, b) {
          return b.total - a.total;
        });
        setTotal(x);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/downloads/download`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        let d = [];
        data.result?.forEach((element) => {
          d.push({ name: element.Theme, value: parseInt(element.total) });
        });

        let x = d.sort(function (a, b) {
          return b.total - a.total;
        });
        setTotalSpecificDownloads(x);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/stats/totalByCurrentDate`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        setTotalVisitsforToday(data.total);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/stats/pagesByCurrentDate`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch messages!!!");
        }
        return res.json();
      })
      .then((data) => {
        let d = [];
        data.result?.forEach((element) => {
          d.push({ name: element.Page, value: parseInt(element.total) });
        });

        let x = d.sort(function (a, b) {
          return b.total - a.total;
        });
        setPageVisitsToday(x);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/users/paginated/0`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setUsers(data.total);
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="wrapper">
      <Navigation
        active="Portal Statistics"
        showing={showing}
        setShowing={setShowing}
      />
      <div className="MainContent">
        <Header2
          active="Portal Statistics"
          showing={showing}
          setShowing={setShowing}
        />
        <div className="StatisticsPage">
          <div className="top">
            <TopItem
              title="Visits"
              date={time}
              amt={totalVisits}
              color="#C9EEFF"
            />
            <TopItem
              title="Downloads"
              date={time}
              amt={totalDownloads}
              color="#F1DEC9"
            />
            <TopItem
              title="Maps"
              date={time}
              amt={stats?.total}
              color="#FBFFB1"
            />
            <TopItem title="Users" date={time} amt={users} color="#FFA3FD" />
          </div>
          <div className="middle">
            <div className="chart">
              <h3>Total Page Visits</h3>
              <StackedAreaChart data={total} />
            </div>
            <div className="chart">
              <h3>Page Visits Today</h3>
              <StackedAreaChart data={pageVisitsToday} />
            </div>
          </div>
          <div className="bottom">
            <div className="chart">
              <h3>Map Categories</h3>
              <CustomShapeBar data={stats?.result} aspect={2} />
            </div>
            <div className="rd">
              <RowData
                name="Messages"
                index={0}
                icon="fa-envelope-open"
                value={stats?.messages}
              />
              <RowData name="Comments" index={1} icon="fa-comment" value={0} />
              <RowData
                name="Knowledge Hub"
                index={2}
                code="&#xf1ea;"
                icon=" fa-newspaper-o"
                value={stats?.publications}
              />
              <RowData
                name="Payments"
                index={3}
                icon="fa-credit-card"
                value={0}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const TopItem = (props) => {
  return (
    <div
      style={{ backgroundColor: props.color ?? props.color }}
      className="tp_item"
    >
      <p>{props.title}</p>
      <h1>{props.amt}</h1>
      <h6>{props.date}</h6>
    </div>
  );
};

const RowData = (props) => {
  const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red", "pink"];

  return (
    <div className="row">
      <i style={{ color: colors[props.index] }} className={"fa " + props.icon}>
        {props.code}
      </i>
      <div>
        <h4>{props.name}</h4>
        <p>{props.value}</p>
      </div>
    </div>
  );
};
