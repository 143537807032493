import React from "react";
import Loading from "../Utils/Loading";

export default class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: false,
      showing: false,
      loading: false,
      color: "#fff",
    };
    this.getId = this.getId.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
  }

  componentDidMount() {
    if ((this.props.index + 1) % 2 == 0) {
      this.setState({ color: "#eaf5fd" });
    }
  }

  getId = (e) => {
    this.setState({ value: e.target.checked });
    this.props.getUserId(this.props.user.UserID, e.target.checked);
  };

  toggleOptions = () => {
    this.setState({ showing: !this.state.showing });
  };

  activateUser = () => {
    this.setState({ loading: true });
    fetch(`/api/users/${this.props.user.UserID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Status: !this.props.user.Status }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        this.props.setChanged(!this.props.changed);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  deleteUser = () => {
    this.setState({ loading: true });
    fetch(`/api/users/${this.props.user.UserID}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        this.props.setChanged(!this.props.changed);
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <>
        <div
          onClick={() => {
            this.toggleOptions();
          }}
          onMouseLeave={() => {
            this.setState({ showing: false });
          }}
          className="row"
          style={{ backgroundColor: this.state.color }}
        >
          <p>{this.props.sn}</p>
          <p>
            {this.props.user.Name.split(" ")
              .map((w) => w[0]?.toUpperCase() + w.substring(1).toLowerCase())
              .join(" ")}
          </p>
          <p>{this.props.user.Email}</p>
          <p>{this.props.user.Phone}</p>
          {this.props.user.Status ? (
            <p className="active">Active</p>
          ) : (
            <p className="inactive">Disabled</p>
          )}
          {this.state.showing && (
            <div className="options">
              {this.props.user.Status ? (
                <p
                  onClick={() => {
                    this.activateUser();
                  }}
                >
                  Deactivate
                </p>
              ) : (
                <p
                  onClick={() => {
                    this.activateUser();
                  }}
                >
                  Activate
                </p>
              )}
              <p
                onClick={() => {
                  this.deleteUser();
                }}
              >
                Delete
              </p>
            </div>
          )}
        </div>
        {this.state.loading && <Loading />}
      </>
    );
  }
}
