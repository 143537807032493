import React, { useRef, useState } from "react";
import InputMap from "../Maps/InputMap";
import Button from "../Utils/ButtonMain";

export default function EditUserDetails(props) {
  const [isError, setIsError] = useState("");
  const [body, updateBody] = useState({
    Phone: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const rfPhone = useRef();
  const rfName = useRef();

  const editDetails = () => {
    if (props.isAuthenticated) {
      let d = body;
      d.Phone = rfPhone.current.value;
      d.Name = rfName.current.value;

      if (d.Phone.length < 10) {
        return setIsError("Phone Number must be usually 10 digits");
      }
      if (!d.Name.includes(" ")) {
        return setIsError("Enter a valid name including your Surname and Firstname)");
      }
      if (d.Name.length < 3) {
        return setIsError("Name too short");
      }

      setIsLoading(false);
      updateBody(d);
      setIsError("");
   
      fetch(`/api/auth/${props.currentUser.UserID}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("Change of details failed");
        })
        .then((data) => {
          setIsLoading(false);
          if (data.success) {
            //props.setToggleEditDetails(false);
            setIsError(data.success);
            setTimeout(() => {
              localStorage.clear();
              props.setIsAuthenticated(false);
              window.location.href = "/";
            }, 1000);
          } else {
        
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError("Login failed!");
        });
    }
  };

  const phonePlaceholder = `${props.currentUser.Phone}`;
  const namePlaceholder = `${props.currentUser.Name}`;

  return (
    <div className="mypopup">
      <div className="container">
        <i
          onClick={() => {
            props.setToggleEditDetails(false);
          }}
          className="fa fa-times"
        ></i>
        <h3>Edit Account Details for {props.currentUser.Name}</h3>
        <h4>{isError}</h4>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <InputMap
            ref={rfName}
            label="Change Name"
            type="text"
            value={namePlaceholder}
          />
          <InputMap
            ref={rfPhone}
            label="Change Phone Number"
            type="number"
            value={phonePlaceholder}
          />
          <button
            onClick={() => {
              editDetails();
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
