import { useEffect, useState } from "react";
import Pagination from "../Utils/Pagination";
import "../../Styles/documents.scss";
import WaveLoading from "../Utils/WaveLoading";
import SelectMain from "../Utils/SelectMain";
import { useRef } from "react";
import { FiEdit } from "react-icons/fi";
import { MdPreview, MdDeleteSweep, MdLockOpen, MdLock } from "react-icons/md";

const criteria = ["Title", "Description", "Owner", "Keywords", "Status" ];

export default function MapCategory(props) {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [crit, setCrit] = useState(criteria[0]);
  const [loading, setLoading] = useState(null);
  const [filter, setFilter] = useState("All");
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState(null);
  const srch = useRef();
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (!query) {
      if (filter == "All") {
        setLoading(true);
        fetch(`/api/data/category/${props.category}/${(currentPage - 1) * 12}`)
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error("");
          })
          .then((data) => {
            setLoading(false);
            setData(data);
          })
          .catch((e) => {
            setLoading(false);
          });
      } else {
        setLoading(true);
        fetch(
          `/api/data/tpaginated/${props.category}/${filter}/${
            (currentPage - 1) * 12
          }`
        )
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error("");
          })
          .then((data) => {
            setLoading(false);
            setData(data);
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    }
    else {
      search(query);
    }
  }, [currentPage, refresh, filter, query]);

  function search(q) {
    setLoading(true);
    fetch(`/api/data/category/${props.category}/search/${crit}/${q}/${(currentPage - 1) * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  return (
    <div className="arc">
      <div className="slist">
        <h1>{props.category}</h1>
        <div className="scontainer">
          <div className="smain">
            <div className="stop">
              <dir className="yr">
                <h4>Search Criteria</h4>
                <SelectMain
                  data={criteria}
                  getSelected={(v) => {
                    setCrit(v);
                  }}
                />
              </dir>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <input
                  ref={srch}
                  type="text"
                  placeholder={"Searching by " + crit}
                  required
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setData(null);
                      setRefresh(!refresh);
                    } else {
                      setQuery(e.target.value)
                    }
                  }}
                />
                <i className="fa fa-search"></i>
              </form>
            </div>

            <div className="mlist">
              {data &&
                data?.data?.length > 0 &&
                data?.data?.map((item, i) => {
                  return <MyDocument key={i} item={item} setRefresh={setRefresh} refresh={refresh}/>;
                })}
            </div>
            <br />
            <br />
            {data && (
              <Pagination
                totalItems={data.total}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
        {loading && <WaveLoading />}
      </div>
    </div>
  );
}

const MyDocument = (props) => {

  const [status, setStatus] = useState(true);

  useEffect(() => {
    setStatus(props.item.Status)
  }, [])

  let formData = new FormData();

  const deleteMap = () => {
    fetch(`/api/data/${props.item.ID}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data.message) {
          window.location.href = "/portal/maps";
        }
      })
      .catch((e) => {});
  };

  const updateStatus = (status) => {
    formData.append("Status", status);
    fetch(`/api/data/${props.item.ID}`, {
      method: "PUT",
      credentials: "include",
      body: formData,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data) {
          props.setRefresh(!props.refresh);
        }
      })
      .catch((e) => {});
  };

  return (
    <div
      title={"Map Description" + "\n\n" + props.item.Description}
      className="stk"
    >
      <div className="edit">
        {props.item.Status === true ? <MdLockOpen
          onClick={() => {
            updateStatus(!props.item.Status);
          }}
          size={28}
          title="Hide from public"
          className="it"
        /> : <MdLock
        onClick={() => {
          updateStatus(!props.item.Status);
        }}
        size={28}
        title="Show to public"
        className="it"
      />}
        <MdPreview
          onClick={() => {
            window.location.href = `/portal/maps/preview/${props.item.Category}/${props.item.ID}`;
          }}
          size={28}
          title="Preview Map"
          className="it"
        />
        <FiEdit
          onClick={() => {
            window.location.href = `/portal/maps/update/${props.item.Category}/${props.item.ID}`;
          }}
          size={28}
          title="Edit Map"
          className="it"
        />
        <MdDeleteSweep
          onClick={() => {
            deleteMap();
          }}
          size={28}
          title="Delete Map"
          className="it"
        />
      </div>

      <img src={`/api/${props.item.Thumbnail}`} alt="" />
      <div className="np">
        <div className="tp">
          <h3>{props.item.Title}</h3>
        </div>
        <h4>Owner: {props.item.Owner}</h4>
        <h4>Date Published: {props.item.updatedAt.split("T")[0]}</h4>
        <p>{props.item.Description}</p>
      </div>
    </div>
  );
};
