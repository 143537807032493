import { useEffect } from "react";
import { useState } from "react";
import logo from "../../assets/imgs/Logo-accent1.png";

export default function Navigation(props) {
  const all = [
    "/",
    "/portal",
    "/portal/downloads",
    "/portal/faqs",
    "https://sandbox.ksa.go.ke",
    "/portal/comments",
    "/portal/maps",
  ];

  const lv1 = [
    "/portal/maps/new",
    "/portal/users",
    "/portal/public/users",
    "/portal/knowledgehub",
    "/portal/messages",
    "/portal/faqs/new",
  ];

  const lv2 = [
    "/portal/payments",
    "/portal/users/new",
    "/portal/knowledgehub/new",
    "/portal/knowledgehub/storymaps/new"
  ];
  const [role, setRole] = useState();
  var jwt = require("jsonwebtoken");

  useEffect(() => {
    const token = localStorage.getItem("nimda_ksa");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        setRole(decoded.Role);
      } catch (error) {
        logout();
      }
    } else {
      logout();
    }
  }, []);

  const logout = () => {
    fetch("/api/auth/logout", {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        localStorage.clear();
        window.location.href = "/";
      })
      .catch((error) => {});
  };

  const Item = (params) => {
    const [showing, setShowing] = useState(false);

    return (
      <div
        style={{
          backgroundColor: showing ? "#60606010" : "transparent",
        }}
        onClick={() => {
          setShowing(!showing); 
        }}
      >
        <div
          onClick={() => {
            if (params.options.length === 0) {
              window.location.href = params.url;
            }
            else{
              setShowing(!showing);
            }
          }}
          className="item"
          style={{
            padding: params.showing ? "1em" : "5x 0 5px 0",
            gridTemplateColumns: params.showing ? "auto 1fr auto" : "auto",
          }}
        >
          <i aria-hidden="true" className={"fa " + params.icon}>
            {params.code}
          </i>
          {params.showing && <p>{params.txt}</p>}
          {params.options.length > 0 && params.showing && (
            <i className="fa fa-angle-right"></i>
          )}
        </div>

        {showing && params.options.length > 0 && (
          <div style={{ padding: "0px 0 16px 0" }}>
            {params.options.map((item, i) => {
              const a = all.indexOf(item?.url);
              const l1 = lv1.indexOf(item?.url);
              const l2 = lv2.indexOf(item?.url);
              if (a !== -1) {
                return (
                  <a key={i} href={item.url}>
                    {item.txt}
                  </a>
                );
              } else if (
                l1 !== -1 &&
                (role === "Super Admin" ||
                  role === "Admin" ||
                  role === "Regular")
              ) {
                return (
                  <a key={i} href={item.url}>
                    {item.txt}
                  </a>
                );
              } else if (
                l2 !== -1 &&
                (role === "Super Admin" || role === "Admin")
              ) {
                return (
                  <a
                    key={i}
                    onClick={() => {
                      if (item.url === "https://sandbox.ksa.go.ke") {
                        window.open("https://sandbox.ksa.go.ke", "_blank");
                      } else {
                        window.location.href = item.url;
                      }
                    }}
                  >
                    {item.txt}
                  </a>
                );
              }
            })}
          </div>
        )}
      </div>
    );
  };
  return (
    <div
      style={{ width: props.showing ? "250px" : "fit-content" }}
      className="navigation"
    >
      <div className="logo">
        <img
          style={{ maxWidth: props.showing ? "70px" : "30px" }}
          src={logo}
          alt=""
        />
        {props.showing && (
          <>
            <h3>Kenya Space Agency</h3>
            <h3>Data Hub</h3>
          </>
        )}
      </div>
      <br></br>
      <br></br>
      <Item
        url="/"
        txt="Home"
        icon="fa-home"
        options={[
          { txt: "Statistics", url: "/portal" },
          {txt: "Downloads", url: "/portal/downloads"},
          { txt: "Messages", url: "/portal/messages" },
          { txt: "Comments", url: "/portal/comments" },
          { txt: "Payments", url: "/portal/payments" },
        ]}
        showing={props.showing}
      />
      <Item
        url="/gis"
        txt="GIS"
        icon="fa-map"
        options={[
          { txt: "Maps", url: "/portal/maps" },
          { txt: "ODC", url: "https://sandbox.ksa.go.ke" },
          { txt: "New", url: "/portal/maps/new" },
        ]}
        showing={props.showing}
      />
      {role !== "Guest" && (
        <>
          <Item
            txt="Portal Users"
            url="/portal"
            icon="fa-user-md"
            options={[
              { txt: "Users", url: "/portal/users" },
              { txt: "New", url: "/portal/users/new" },
            ]}
            showing={props.showing}
          />
          <Item
            txt="Public Users"
            url="/mobile"
            icon="fa-user"
            options={[{ txt: "Users", url: "/portal/public/users" }]}
            showing={props.showing}
          />
        </>
      )}
      <Item
        txt="Knowledge Hub"
        url="/settings"
        icon="fa-file-pdf-o"
        code="&#xf1c1;"
        options={[
          { txt: "List", url: "/portal/knowledgehub" },
          { txt: "New", url: "/portal/knowledgehub/new" },
          { txt: "New StoryMap", url: "/portal/knowledgehub/storymaps/new" },
        ]}
        showing={props.showing}
      />
      <Item
        txt="FAQs"
        icon="fa-question-circle"
        options={[
          { txt: "List", url: "/portal/faqs" },
          { txt: "New", url: "/portal/faqs/new" },
        ]}
        showing={props.showing}
      />

      {props.showing && (
        <h6>
          Powered by <br></br>
          <a href="https://osl.co.ke">Oakar Services</a>{" "}
        </h6>
      )}
    </div>
  );
}
