import logo from "../../assets/imgs/Logo-accent1.png";
export default function LgTop(props) {
  return (
    <div className="top">
      <div className="div2equal">
        <div className="div1">
          <img src={logo} className="lg" alt="Kenya Space Agency Logo" />
          <h2>Data Hub</h2>
        </div>
      </div>
    </div>
  );
}
