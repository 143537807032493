import Header2 from "../components/Utils/Header2";
import Navigation from "../components/Utils/Navigation";
import "../Styles/MessagesPage.scss";
import { useState } from "react";
import { useEffect } from "react";

export default function PaymentsPage(props) {
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const data = useState(null);
  return (
    <div className="wrapper">
      <Navigation showing={showing} setShowing={setShowing} active="Payments" />
      <div className="MainContent">
        <Header2
          showing={showing}
          setShowing={setShowing}
          active="Payments data"
        />
        <div className="MessagesPage">
          <div className="list">
            <h3>Payments</h3>
            <hr />
            <div className="content">
              <p>Coming soon!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
