import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import { useEffect, useState } from "react";
// importing pages
import LoginPage from "./Pages/LoginPage";
import StatisticsPage from "./Pages/StatisticsPage";
import MessagesPage from "./Pages/MessagesPage";
import CommentsPage from "./Pages/CommentsPage";
import PaymentsPage from "./Pages/PaymentsPage";
import InstancesPage from "./Pages/InstancesPage";
import SingleInstancePage from "./Pages/SingleInstancePage";
import NewInstancesPage from "./Pages/NewInstancesPage";
import PrivateUsersPage from "./Pages/PrivateUsersPage";
import PrivateUsersCreatePage from "./Pages/PrivateUsersCreatePage";
import PublicUsersPage from "./Pages/PublicUsersPage";
import NotFound from "./Pages/404";
import ProtectedRoute from "./components/login/ProtectedRoute";
import KnowldeHubPage from "./Pages/KnowldeHubPage";
import PublicationsCreatePage from "./Pages/PublicationsCreate";
import PublicationInstance from "./Pages/PublicationInstance";
import EditPublication from "./Pages/EditPublication";
import FAQs from "./Pages/FAQs";
import AOS from "aos";
import FAQsNew from "./Pages/FAQsNew";
import StoryMapsCreate from "./Pages/StoryMapsCreate";
import StoryMapPage from "./Pages/StoryMapPage";
import Downloads from "./Pages/Downloads";
// importing pages

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [role, setRole] = useState();

  var jwt = require("jsonwebtoken");

  useEffect(() => {
    const token = localStorage.getItem("nimda_ksa");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        setRole(decoded.Role);
      } catch (error) {}
    } else {
    }
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <LoginPage />
        </Route>
        <ProtectedRoute exact path="/portal" component={StatisticsPage} />
        <ProtectedRoute exact path="/portal/downloads" component={Downloads} />
        <ProtectedRoute exact path="/portal/faqs" component={FAQs} />
        <ProtectedRoute
          exact
          path="/portal/comments"
          component={CommentsPage}
        />
        <ProtectedRoute exact path="/portal/maps" component={InstancesPage} />

        <ProtectedRoute
          path="/portal/maps/preview/*"
          component={SingleInstancePage}
        />

        <ProtectedRoute
          path="/portal/maps/update/*"
          component={SingleInstancePage}
        />
        <ProtectedRoute
          path="/portal/knowledgehub/storymaps/new"
          component={StoryMapsCreate}
        />

        {(role === "Admin" || role === "Super Admin") && (
          <>
            <ProtectedRoute
              exact
              path="/portal/payments"
              component={PaymentsPage}
            />
            <ProtectedRoute
              path="/portal/maps/update/*"
              component={SingleInstancePage}
            />
            <ProtectedRoute
              exact
              path="/portal/users/new"
              component={PrivateUsersCreatePage}
            />
            <ProtectedRoute
              exact
              path="/portal/publication/update/*"
              component={EditPublication}
            />
            <ProtectedRoute
              exact
              path="/portal/knowledgehub/new"
              component={PublicationsCreatePage}
            />
            <ProtectedRoute
              exact
              path="/portal/maps/new"
              component={NewInstancesPage}
            />
            <ProtectedRoute
              exact
              path="/portal/users"
              component={PrivateUsersPage}
            />
            <ProtectedRoute
              exact
              path="/portal/public/users"
              component={PublicUsersPage}
            />
            <ProtectedRoute
              exact
              path="/portal/knowledgehub"
              component={KnowldeHubPage}
            />
            <ProtectedRoute
              exact
              path="/portal/knowledgehub/storymap/*"
              component={StoryMapPage}
            />
            <ProtectedRoute
              exact
              path="/portal/messages"
              component={MessagesPage}
            />
            <ProtectedRoute exact path="/portal/faqs/new" component={FAQsNew} />
          </>
        )}

        {role === "Regular" && (
          <>
            <ProtectedRoute exact path="/portal/faqs/new" component={FAQsNew} />
            <ProtectedRoute
              exact
              path="/portal/maps/new"
              component={NewInstancesPage}
            />
            <ProtectedRoute
              exact
              path="/portal/users"
              component={PrivateUsersPage}
            />
            <ProtectedRoute
              exact
              path="/portal/public/users"
              component={PublicUsersPage}
            />
            <ProtectedRoute
              exact
              path="/portal/knowledgehub"
              component={KnowldeHubPage}
            />
            <ProtectedRoute
              exact
              path="/portal/messages"
              component={MessagesPage}
            />
          </>
        )}
        <ProtectedRoute
          path="/portal/knowledgehub/preview/*"
          component={PublicationInstance}
        />

        {/* <Route exact path="*">
          <NotFound />
        </Route> */}
      </Switch>
    </Router>
  );
}

export default App;
