import "../Styles/LoginPage.scss";
import LgTop from "../components/login/LgTop";
import LgBottom from "../components/login/LgBottom";
import LgForm from "../components/login/LgForm";

export default function LoginPage(props) {
  return (
    <div className="LoginPage">
      <LgTop />
      <LgForm />
      <LgBottom />
    </div>
    
  );
}
