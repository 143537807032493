import Pagination from "../Utils/Pagination";
import User from "./User";
import { useEffect, useState } from "react";

export default function Users(props) {
  const [data, setData] = useState(null);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [changed, setChanged] = useState(false);
  const [sNo, setSNo] = useState(offset * 12 + 1);

  useEffect(() => {
    setData(null);
    setSNo(offset * 12 + 1);
    fetch(`/api/users/paginated/${offset * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setData(data);
        props.setTotal(data.total);
        props.setActive(data.active);
        props.setInactive(data.inactive);
        var today = new Date();
        var date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        var time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        var dateTime = date + " " + time;
        props.setTime(dateTime);
      })
      .catch((err) => {});
  }, [changed, currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div>
      <div className="head">
        <h4>SN</h4>
        <h4>Name</h4>
        <h4>Email</h4>
        <h4>Phone</h4>
        <h4>Status</h4>
      </div>
      <div className="content">
        {data &&
          data.result &&
          data.result.map((user, index) => {
            return (
              <User
                changed={changed}
                setChanged={setChanged}
                key={user.UserID}
                user={user}
                index={index}
                sn={index + sNo}
              />
            );
          })}
      </div>
      {data && (
        <Pagination
          totalItems={data.total}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
}
