import React, { useEffect, useRef, useState } from "react";
import UserAccount from "../userManagement/userAccount";
import EditUserDetails from "../userManagement/editUserDetails";
import ChangePassword from "../userManagement/changePassword";

export default function Header2(props) {
  var jwt = require("jsonwebtoken");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [showSettings, setShowSettings] = useState(false);
  const [toggleAccount, setToggleAccount] = useState(false);
  const [toggleEditDetails, setToggleEditDetails] = useState(false);
  const [toggleChangePass, setToggleChangePass] = useState(false);

  const [clicked, setClicked] = useState(false);

  const toggleMenu = () => {
    setClicked(!clicked);
  };

  useEffect(() => {
    const token = localStorage.getItem("nimda_ksa");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        setCurrentUser(decoded);

        if (Date.now() >= decoded.exp * 1000) {
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [isAuthenticated]);

  const logout = () => {
    fetch("/api/auth/logout", {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setIsAuthenticated(false);
        localStorage.clear();
        window.location.href = "/";
      })
      .catch((error) => {});
  };

  return (
    <div
      onMouseLeave={() => {
        setShowSettings(false);
      }}
      className="header"
    >
      <i
        className="fa fa-bars"
        onClick={() => {
          props.setShowing(!props.showing);
        }}
      ></i>
      <div onMouseEnter={() => setShowSettings(true)} className="right">
        <p>{currentUser.Name}</p>
        <i className="fa fa-angle-down"></i>
        <i className="fa fa-user"></i>
      </div>

      {toggleAccount && (
        <UserAccount
          setToggleAccount={setToggleAccount}
          currentUser={currentUser}
        />
      )}
      {toggleEditDetails && (
        <EditUserDetails
          setToggleEditDetails={setToggleEditDetails}
          setToggleChangePass={setToggleChangePass}
          setIsAuthenticated={setIsAuthenticated}
          isAuthenticated={isAuthenticated}
          currentUser={currentUser}
        />
      )}
      {toggleChangePass && (
        <ChangePassword
          setToggleChangePass={setToggleChangePass}
          setIsAuthenticated={setIsAuthenticated}
          isAuthenticated={isAuthenticated}
          currentUser={currentUser}
        />
      )}
      {showSettings && (
        <div className="dropdown">
          <p
            onClick={() => {
              setToggleAccount(true);
            }}
          >
            <i className="fa fa-address-card"></i> Account
          </p>
          <p
            onClick={() => {
              setToggleEditDetails(true);
          
            }}
          >
            <i className="fa fa-user"></i>
            Edit Details
          </p>
          <p
            onClick={() => {
              setToggleChangePass(true);
            }}
          >
            <i className="fa fa-lock"></i>
            Change Password
          </p>
          <p
            onClick={() => {
              logout();
            }}
          >
            <i className="fa fa-power-off"></i> Logout
          </p>
        </div>
      )}
    </div>
  );
}
