import React from "react";
import { FaRegTrashAlt } from "react-icons/fa";

export default function AccordionBody(props) {
  let id = props.item.ID;
  const deleteFaq = () => {
    fetch(`/api/faq/${id}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Failed!!!");
      })
      .then((data) => {
        props.setIsErr(data.message);
        window.location.reload();
      })
      .catch((e) => {
       
      });
  };
  return (
    <div className="accordion-body">
      <h4>{props.item.Question}</h4>
      <p>{props.item.Answer}</p>
      <div className="accordion-footer">
        <FaRegTrashAlt
          style={{ marginLeft: "2em", cursor: "pointer" }}
          onClick={() => {
            deleteFaq();
          }}
        />
      </div>
    </div>
  );
}
