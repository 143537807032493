import "../Styles/Publications.scss";
import Header2 from "../components/Utils/Header2";
import Navigation from "../components/Utils/Navigation";
import Input from "../components/Users/UsrInput";
import Button from "../components/Utils/ButtonMain";
import Select from "../components/Users/UsrSelect";
import { useRef, useState } from "react";
import Loading from "../components/Utils/Loading";
import { useEffect } from "react";

export default function FAQsNew(props) {
  const rfQuestion = useRef();
  const rfAnswer = useRef();
  const [body, updateBody] = useState({ Question: null });
  const [msg, setMsg] = useState("");
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const newFaq = () => {
    let d = body;
    d.Question = rfQuestion.current.value;
    d.Answer = rfAnswer.current.value;
    updateBody(d);

    if (!body.Question || !body.Answer) {
      return setMsg("Inputs cannot be empty!!!");
    }

    fetch("/api/faqs/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error("Something went wrong!!!");
        }
      })
      .then((data) => {
        if (data.success) {
          setMsg(data.success);
          setTimeout(() => {
            window.location.href = "/portal/faqs";
          }, 1000);
        } else {
          setMsg(data.error);
        }
      })
      .catch((e) => {
        
      });
  };

  return (
    <div className="wrapper">
      <Navigation
        showing={showing}
        setShowing={setShowing}
        active="Add a Publication"
      />
      <div className="MainContent">
        <Header2
          showing={showing}
          setShowing={setShowing}
          active="Create Publication"
        />
        <div className="publications">
          <div className="list">
            <h3>New FAQ</h3>
            <hr />
            <div className="new">
              <form
                autoComplete="none"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <span className="err">{msg}</span>
                <Input
                  ref={rfQuestion}
                  type="text"
                  label="Question *"
                  placeholder="Question *"
                />
                <div className="usrinput">
                  <h4>Answer *</h4>
                  <textarea
                    id="w3review"
                    name="w3review"
                    rows="10"
                    cols="80"
                    ref={rfAnswer}
                    placeholder="Answer *"
                    type="textarea"
                  />
                </div>

                <button
                  onClick={() => {
                    newFaq();
                  }}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
