import Pagination from "../Utils/Pagination";
import User from "./User";
import { useEffect, useState } from "react";

export default function Users(props) {
  const [data, setData] = useState(null);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [changed, setChanged] = useState(false);
  const [sNo, setSNo] = useState(offset * 12 + 1);
  const [role, setRole] = useState();

  var jwt = require("jsonwebtoken");

  useEffect(() => {
    const token = localStorage.getItem("nimda_ksa");

    if (token) {
      var decoded = jwt.decode(token);
      setRole(decoded.Role);
    } else {
      setRole();
    }
  }, []);

  useEffect(() => {
    setData(null);
    setSNo(offset * 12 + 1);
    fetch(`/api/auth/paginated/${(currentPage - 1) * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((err) => {});
  }, [changed, currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <div className="head">
        <h4>SN</h4>
        <h4>Name</h4>
        <h4>Email</h4>
        <h4>Phone</h4>
        <h4>Role</h4>
        <h4>Status</h4>
      </div>
      <div className="content">
        {data &&
          data.result &&
          data.result.map((user, index) => {
            return (
              <User
                changed={changed}
                setChanged={setChanged}
                key={user.UserID}
                user={user}
                index={index}
                sn={index + sNo}
                role={role}
              />
            );
          })}
      </div>
      {data && (
        <Pagination
          totalItems={data.total}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
}
